import { useState, useEffect } from "react";
import { Container, SelectContainer, SelectDiv } from "./Select.style";

import styled from "styled-components";

const Select = (props) => {
  const {
    defaultValue = null,
    defaultOption = null,
    label = "",
    type = "",
    optionsheader = "",
    options = [],
    width,
    exposedValue = null,
    foundValue = null,
  } = props;

  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (defaultOption) {
      const wrkOption = options.find((option) => option.name === defaultOption);
      if (wrkOption) {
        const wrkId = wrkOption?.id ?? null;
        if (wrkId) {
          setSelectedValue(wrkId);
          props.onChange(wrkId);
        }
      }
    }
    // eslint-disable-next-line
  }, [defaultOption]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
      props.onChange(defaultValue);
    }

    // eslint-disable-next-line
  }, [defaultValue]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    props.onChange(value);
  };

  useEffect(() => {
    if (selectedValue && options && typeof foundValue === "function") {
      const wrkOption = options.find((option) => option.id === selectedValue);

      if (wrkOption) {
        foundValue(selectedValue);
      } else {
        foundValue("");
      }
    }
  }, [options]);

  let key = 0;

  try {
    return (
      <Container>
        {label !== "" && <label>{label}</label>}
        <SelectContainer style={{ width: props.width }}>
          <SelectDiv>
            <StyledSelect
              width={width}
              value={exposedValue ?? selectedValue}
              onChange={(e) => handleChange(e)}
            >
              {optionsheader !== "" && (
                <option value="">{optionsheader}</option>
              )}

              {type === "associative"
                ? options.forEach((value) => {})
                : options?.map((option) => (
                    <option key={++key} value={option.id}>
                      {option.name}
                    </option>
                  ))}
            </StyledSelect>
          </SelectDiv>
        </SelectContainer>
      </Container>
    );
  } catch (e) {
    return (
      <Container>
        {label !== "" && <label>{label}</label>}
        <SelectContainer style={{ width: props.width }}>
          <SelectDiv>
            <StyledSelect
              width={width}
              value={exposedValue ?? selectedValue}
              onChange={(e) => handleChange(e)}
            >
              {optionsheader !== "" && (
                <option value="">{optionsheader}</option>
              )}
              <option key={1} value={1}>
                ERROR retrieving data
              </option>
            </StyledSelect>
          </SelectDiv>
        </SelectContainer>
      </Container>
    );
  }
};
export default Select;

const StyledSelect = styled.select`
  width: ${(props) => props.width || "20rem"};
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  height: 40px;
  appearance: none;

  text-indent: 1px;
  text-overflow: "";
  box-sizing: border-box;
  flex: 1 0;
  background: #ffffff;
  background-color: transparent;
  padding-left: 0.5rem;

  border: 0;
  border-radius: 4px;
  z-index: 100;
  padding-right: 1.5rem;
`;
